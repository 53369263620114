import { useFeatureFlags } from "src/context/FeatureFlag";

import CovidDownloads from "./CovidDownloads";
import DataDictionariesCard from "./DataDictionariesCard";
import FluDownloads from "./FluDownloads";
import MpoxDownloads from "./MpoxDownloads";
import NoroDownloads from "./NoroDownloads";
import RsvDownloads from "./RsvDownloads";
import SubstancesDownloads from "./SubstancesDownloads";
import { useDownloadHubLinks } from "./links";
import type { Links } from "./types";

interface DownloadHubPagePureProps {
  links?: Links;
  loading?: boolean;
}

export const DownloadHubPagePure = ({
  links,
  loading,
}: DownloadHubPagePureProps) => {
  const cardProps = { links, loading, className: "max-w-[280px]" };
  const flags = useFeatureFlags();

  return (
    <div>
      <h1 className="text-h1">Download Hub</h1>
      <div className="text-h3 mb-6">
        Access customized wastewater surveillance data files for your research.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-16">
        <CovidDownloads {...cardProps} />
        {flags.fluRsvPagesUi ? (
          <>
            <FluDownloads {...cardProps} />
            <RsvDownloads {...cardProps} />
          </>
        ) : null}
        <NoroDownloads {...cardProps} />
        <MpoxDownloads {...cardProps} />
        <SubstancesDownloads {...cardProps} />
      </div>
      <DataDictionariesCard links={links} className="mt-8" />
    </div>
  );
};

const DownloadHubPage = () => {
  const { data, isLoading } = useDownloadHubLinks();
  return <DownloadHubPagePure links={data} loading={isLoading} />;
};

export default DownloadHubPage;
