import Loading from "./Loading";

interface QueryWrapperProps {
  data?: unknown;
  isLoading: boolean;
  loadingMessage?: React.ReactNode;
  children: React.ReactNode;
}

const QueryWrapper = ({
  data,
  isLoading,
  children,
  loadingMessage,
}: QueryWrapperProps) => {
  if (isLoading) {
    return <Loading tip={loadingMessage} />;
  } else if (data) {
    return <>{children}</>;
  } else {
    return (
      <span>Unable to load data at this time. Please try again later.</span>
    );
  }
};

export default QueryWrapper;
