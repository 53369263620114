import hrsImage from "src/images/hrs_molecule.png";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

// TODO: lcmsKits
const datasets = ["hrsKits", "lcmsNationalAverage"] as const;

interface SubstancesDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const SubstancesDownloads = (props: SubstancesDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="Substances"
    singularTitle="Substance"
    image={hrsImage}
  />
);

export default SubstancesDownloads;
