"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/datasets.ts
var datasets_exports = {};
__export(datasets_exports, {
  biobotRiskTierByDataset: () => biobotRiskTierByDataset,
  biobotRiskTiers: () => biobotRiskTiers,
  countyDatasets: () => countyDatasets,
  datasetDisplayName: () => datasetDisplayName,
  datasetNames: () => datasetNames,
  datasetShortcuts: () => datasetShortcuts,
  datasetSorter: () => datasetSorter,
  formatDatasetName: () => formatDatasetName,
  hrsDatasets: () => hrsDatasets,
  kitDatasets: () => kitDatasets,
  lcmsDatasets: () => lcmsDatasets,
  lcmsNationalDatasets: () => lcmsNationalDatasets,
  nationalDatasets: () => nationalDatasets,
  opioidsDatasets: () => opioidsDatasets,
  pcrDatasets: () => pcrDatasets,
  pcrNationalDatasets: () => pcrNationalDatasets,
  pcrTargetByDataset: () => pcrTargetByDataset,
  regionalDatasets: () => regionalDatasets,
  resolveDatasetShortcuts: () => resolveDatasetShortcuts,
  stateDatasets: () => stateDatasets,
  sudDatasets: () => sudDatasets
});
module.exports = __toCommonJS(datasets_exports);
var pcrDatasets = [
  "covid",
  "flu",
  "norovirus",
  "rsv"
  // we produce these datasets, but don't serve them on the dashboard
  // "covid_variants",
  // "mpox",
];
var hrsDatasets = [
  "cocaine",
  "fentanyl",
  "methamphetamine",
  "nicotine",
  "xylazine"
];
var sudDatasets = ["buprenorphine", "methadone", "naloxone"];
var opioidsDatasets = [
  // Group: Illicit Opioids & Adjacent Substances
  "acetylfentanyl",
  "carfentanil",
  "heroin",
  "sufentanil",
  "tianeptine",
  // Group: Rx Opioids
  "codeine",
  "dihydrocodeine",
  "hydrocodone",
  "hydromorphone",
  "morphine",
  "oxycodone",
  "oxymorphone",
  "tramadol"
];
var lcmsDatasets = [
  ...hrsDatasets,
  ...sudDatasets,
  ...opioidsDatasets
];
var kitDatasets = [...pcrDatasets, ...lcmsDatasets];
var datasetShortcuts = {
  "*kit": kitDatasets,
  "*hrs": hrsDatasets,
  "*sud": sudDatasets,
  "*opioid": opioidsDatasets,
  "*lcms": lcmsDatasets,
  "*pcr": pcrDatasets
};
var countyDatasets = [
  "covid:county",
  "flu:county",
  "norovirus:county",
  "rsv:county",
  "covid:county:interpolated",
  "flu:county:interpolated",
  "rsv:county:interpolated"
];
var stateDatasets = [
  "covid:state",
  "flu:state",
  "norovirus:state",
  "rsv:state"
];
var regionalDatasets = [
  "covid:regional",
  "flu:regional",
  "norovirus:regional",
  "rsv:regional"
];
var pcrNationalDatasets = [
  "covid:national",
  "flu:national",
  "norovirus:national",
  "rsv:national"
];
var lcmsNationalDatasets = [
  // HRS
  "cocaine:national",
  "fentanyl:national",
  "methamphetamine:national",
  "naloxone:national",
  "nicotine:national",
  "xylazine:national",
  // SUD
  "buprenorphine:national",
  "methadone:national",
  "naloxone:national",
  // Group: Illicit Opioids & Adjacent Substances
  "acetylfentanyl:national",
  "carfentanil:national",
  "heroin:national",
  "sufentanil:national",
  "tianeptine:national",
  // Group: Rx Opioids
  "codeine:national",
  "dihydrocodeine:national",
  "hydrocodone:national",
  "hydromorphone:national",
  "morphine:national",
  "oxycodone:national",
  "oxymorphone:national",
  "tramadol:national"
];
var nationalDatasets = [
  ...pcrNationalDatasets,
  ...lcmsNationalDatasets
];
var datasetNames = [
  ...kitDatasets,
  ...countyDatasets,
  ...stateDatasets,
  ...regionalDatasets,
  ...nationalDatasets
];
var resolveDatasetShortcuts = (xs) => xs.flatMap((x) => datasetShortcuts[x] ?? [x]);
var pcrTargetByDataset = {
  covid: ["sars-cov-2"],
  norovirus: ["NoV_GI", "NoV_GII"],
  rsv: ["RSV"],
  flu: ["InfA", "InfB"]
};
var baseTiers = ["Minimal", "Low", "Moderate", "High", "Very high"];
var biobotRiskTiers = ["Preseason", ...baseTiers];
var biobotRiskTierByDataset = {
  covid: baseTiers,
  rsv: biobotRiskTiers,
  flu: biobotRiskTiers,
  // norovirus does not have tiers, but ZodEnum can't handle an empty array
  norovirus: baseTiers
};
var datasetDisplayName = {
  // PCR
  covid: "SARS-CoV-2",
  flu: "Influenza",
  norovirus: "Norovirus",
  rsv: "RSV",
  // HRS
  cocaine: "Cocaine",
  fentanyl: "Fentanyl",
  methamphetamine: "Methamphetamine",
  nicotine: "Nicotine",
  xylazine: "Xylazine",
  // SUD
  buprenorphine: "Buprenorphine",
  methadone: "Methadone",
  naloxone: "Naloxone",
  // Illicit Opioids & Adjacent Substances
  acetylfentanyl: "Acetyl Fentanyl",
  carfentanil: "Carfentanil",
  heroin: "Heroin",
  sufentanil: "Sufentanil",
  tianeptine: "Tianeptine",
  // Rx Opioids
  codeine: "Codeine",
  dihydrocodeine: "Dihydrocodeine",
  hydrocodone: "Hydrocodone",
  hydromorphone: "Hydromorphone",
  morphine: "Morphine",
  oxycodone: "Oxycodone",
  oxymorphone: "Oxymorphone",
  tramadol: "Tramadol"
};
var formatDatasetName = (dataset) => {
  const [kitDataset, level] = dataset.split(":");
  const formattedName = datasetDisplayName[kitDataset] ?? kitDataset;
  switch (level) {
    case "national":
      return `${formattedName} (National Average)`;
    case "regional":
      return `${formattedName} (Regional Average)`;
    case "state":
      return `${formattedName} (State Average)`;
    case "county":
      return `${formattedName} (County Average)`;
    default: {
      const _exhaustivenessCheck = level;
      return formattedName;
    }
  }
};
var datasetRank = (dataset) => {
  if (pcrDatasets.includes(dataset)) {
    return 0;
  } else if (lcmsDatasets.includes(dataset)) {
    return 1;
  } else {
    return 2;
  }
};
var datasetSorter = (dataset) => {
  const kitDataset = dataset.split(":")[0];
  return `${datasetRank(kitDataset)} ${datasetDisplayName[kitDataset]} ${dataset}`;
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  biobotRiskTierByDataset,
  biobotRiskTiers,
  countyDatasets,
  datasetDisplayName,
  datasetNames,
  datasetShortcuts,
  datasetSorter,
  formatDatasetName,
  hrsDatasets,
  kitDatasets,
  lcmsDatasets,
  lcmsNationalDatasets,
  nationalDatasets,
  opioidsDatasets,
  pcrDatasets,
  pcrNationalDatasets,
  pcrTargetByDataset,
  regionalDatasets,
  resolveDatasetShortcuts,
  stateDatasets,
  sudDatasets
});
