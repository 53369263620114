import dayjs from "dayjs";
import { mapValues } from "lodash";

import api from "src/api";
import { useCustomer } from "src/context/Customer";

import type { ApiLinks, Dataset, DatasetInfo, Datasets, Links } from "./types";

export const datasetInfo = (
  customer_name: string,
): Record<Dataset, DatasetInfo> => {
  const prefix = customer_name
    .replace(/ /g, "_")
    .replace(/[^A-Za-z0-9_-]/g, "_")
    .replace(/__+/g, "_");
  const today = dayjs().format("YYYY-MM-DD");
  return {
    // covid
    covidCDC: {
      filename: `${prefix}_nwss_cdc_${today}.csv`,
      label: "CDC NWSS+",
    },
    covidNWSS: {
      filename: `${prefix}_nwss_${today}.csv`,
      label: "NWSS+",
    },
    covidKits: {
      filename: `${prefix}_sars_cov_2_${today}.csv`,
      label: "CSV",
    },
    covidCountyInterpolated: {
      filename: `${prefix}_ai_subscription_county_sars_cov_2_${today}.csv`,
      label: "County AI Subscription",
      dataDictionary: true,
    },
    covidCountyAverage: {
      filename: `${prefix}_subscription_county_sars_cov_2_${today}.csv`,
      label: "County Subscription",
      dataDictionary: true,
    },
    covidStateAverage: {
      filename: `${prefix}_subscription_state_sars_cov_2_${today}.csv`,
      label: "State Subscription",
      dataDictionary: true,
    },
    covidRegionalAverage: {
      filename: `${prefix}_subscription_regional_sars_cov_2_${today}.csv`,
      label: "Regional Subscription",
      dataDictionary: true,
    },
    covidNationalAverage: {
      filename: `${prefix}_subscription_national_sars_cov_2_${today}.csv`,
      label: "National Subscription",
      dataDictionary: true,
    },
    // flu
    fluKits: { filename: `${prefix}_influenza_${today}.csv`, label: "CSV" },
    fluCountyInterpolated: {
      filename: `${prefix}_ai_subscription_county_influenza_${today}.csv`,
      label: "County AI Subscription",
      dataDictionary: true,
    },
    fluCountyAverage: {
      filename: `${prefix}_subscription_county_influenza_${today}.csv`,
      label: "County Subscription",
      dataDictionary: true,
    },
    fluStateAverage: {
      filename: `${prefix}_subscription_state_influenza_${today}.csv`,
      label: "State Subscription",
      dataDictionary: true,
    },
    fluRegionalAverage: {
      filename: `${prefix}_subscription_regional_influenza_${today}.csv`,
      label: "Regional Subscription",
      dataDictionary: true,
    },
    fluNationalAverage: {
      filename: `${prefix}_subscription_national_influenza_${today}.csv`,
      label: "National Subscription",
      dataDictionary: true,
    },
    // rsv
    rsvKits: { filename: `${prefix}_rsv_${today}.csv`, label: "CSV" },
    rsvCountyInterpolated: {
      filename: `${prefix}_ai_subscription_county_rsv_${today}.csv`,
      label: "County AI Subscription",
      dataDictionary: true,
    },
    rsvCountyAverage: {
      filename: `${prefix}_subscription_county_rsv_${today}.csv`,
      label: "County Subscription",
      dataDictionary: true,
    },
    rsvStateAverage: {
      filename: `${prefix}_subscription_state_rsv_${today}.csv`,
      label: "State Subscription",
      dataDictionary: true,
    },
    rsvRegionalAverage: {
      filename: `${prefix}_subscription_regional_rsv_${today}.csv`,
      label: "Regional Subscription",
      dataDictionary: true,
    },
    rsvNationalAverage: {
      filename: `${prefix}_subscription_national_rsv_${today}.csv`,
      label: "National Subscription",
      dataDictionary: true,
    },
    // norovirus
    norovirusKits: {
      filename: `${prefix}_norovirus_${today}.csv`,
      label: "CSV",
      dataDictionary: true,
    },
    norovirusCountyAverage: {
      filename: `${prefix}_subscription_county_norovirus_${today}.csv`,
      label: "County Subscription",
    },
    norovirusStateAverage: {
      filename: `${prefix}_subscription_state_norovirus_${today}.csv`,
      label: "State Subscription",
    },
    norovirusRegionalAverage: {
      filename: `${prefix}_subscription_regional_norovirus_${today}.csv`,
      label: "Regional Subscription",
    },
    norovirusNationalAverage: {
      filename: `${prefix}_subscription_national_norovirus_${today}.csv`,
      label: "National Subscription",
    },
    // mpox
    mpoxNWSS: {
      filename: `${prefix}_nwss_mpx_${today}.csv`,
      label: "NWSS+",
    },
    // hrs
    hrsKits: { filename: `${prefix}_hrs_${today}.csv`, label: "CSV" },
    lcmsNationalAverage: {
      filename: `${prefix}_subscription_national_lcms_${today}.csv`,
      label: "National Subscription",
    },
  };
};

const selectLinks = (x: { data: ApiLinks }) => {
  const info = datasetInfo("demo");
  return mapValues(x.data, (href: string, k: keyof ApiLinks) => ({
    href,
    ...info[k],
  })) as unknown as Links;
};

// This should be less than the token expiration time
const linkTtlMs = 1 * 60 * 1000;

export const useDownloadHubLinks = () => {
  const { customer_name } = useCustomer();
  return api.export.links.useQuery(
    {
      filenames: mapValues(datasetInfo(customer_name), "filename"),
    },
    {
      select: selectLinks,
      staleTime: linkTtlMs,
      cacheTime: linkTtlMs,
      refetchInterval: linkTtlMs,
    },
  );
};

export interface LinkComponentProps<T extends Datasets> {
  links?: Pick<Links, T[number]>;
  loading?: boolean;
}

interface UseLinkComponentProps<T extends Datasets>
  extends LinkComponentProps<T> {
  datasets: T;
}

export const useLinkComponent = <T extends Datasets>({
  links,
  loading,
  datasets,
}: UseLinkComponentProps<T>) => {
  if (loading) {
    return { state: "loading", links: null } as const;
  } else if (links && datasets.some((k) => links[k]?.href)) {
    return { state: "data", links } as const;
  } else {
    return { state: "no-data", links: null } as const;
  }
};
