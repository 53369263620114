import fluImage from "src/images/flu.png";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

const datasets = [
  "fluKits",
  "fluCountyInterpolated",
  "fluCountyAverage",
  "fluStateAverage",
  "fluRegionalAverage",
  "fluNationalAverage",
] as const;

interface FluDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const FluDownloads = (props: FluDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="Influenza"
    image={fluImage}
  />
);

export default FluDownloads;
