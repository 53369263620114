import { DatasetName, formatDatasetName } from "@repo/data/datasets";

import BiobotCard from "src/components/BiobotCard";
import BiobotCollapse from "src/components/BiobotCollapse";

import { DownloadLink } from "./Buttons";
import type { Links } from "./types";

interface DataDictionariesCardProps {
  links?: Links;
  className?: string;
}

const titleText = (key: string, label: string) => {
  const [dataset] = key.match(/^[a-z]+/) ?? [];
  if (!dataset) {
    return key;
  }
  return `${formatDatasetName(dataset as DatasetName)} ${label}`;
};

const csvFilename = (key: string, label: string) => {
  const text = `biobot_${titleText(key, label)}_format.csv`;
  return text.toLowerCase().replace(/[^a-z0-9_.]/g, "_");
};

const DataDictionariesCard = ({
  links,
  className,
}: DataDictionariesCardProps) => {
  const dictionaries = Object.entries(links ?? {})
    .filter(([_, link]) => link?.href && link.dataDictionary)
    .map(([key, link]) => ({
      key,
      label: link!.label,
    }));
  if (dictionaries.length === 0) {
    return null;
  }
  return (
    <BiobotCard className={className}>
      <BiobotCollapse>
        <BiobotCollapse.Panel key="0" header="Data Dictionaries">
          {dictionaries.map(({ key, label }) => (
            <DownloadLink
              key={key}
              type="default"
              label={titleText(key, label)}
              href={`${process.env.PUBLIC_URL}/data-dictionaries/${key}.csv`}
              download={csvFilename(key, label)}
              gaAction={`${key}-dictionary-downloaded`}
            />
          ))}
        </BiobotCollapse.Panel>
      </BiobotCollapse>
    </BiobotCard>
  );
};

export default DataDictionariesCard;
