import type { RedocTheme } from "@repo/redoc-embed";

import colors from "src/colors";
import { themeConfig } from "src/theme/themeConfig";

import prismTomorrowNightEighties from "./prismTomorrowNightEighties";

// This uses styled-components syntax
const extensionCss = {
  Prism: `
    ${prismTomorrowNightEighties}

    code[class*='language-'],
    pre[class*='language-'] {
      font-size: 0.875em;
      text-shadow: none;
    }
  `,
  H1: `color: ${colors["navy-7"]}`,
  H2: `color: ${colors["navy-7"]}`,
  H3: `color: ${colors["navy-7"]}`,
  Markdown: `h1, h2, h3, { color: ${colors["navy-7"]} }`,
};

const redocTheme = {
  typography: {
    fontSize: "1rem",
    fontFamily: themeConfig.token.fontFamily,
    headings: {
      fontWeight: "500",
      fontFamily: themeConfig.token.fontFamily,
    },
    code: {
      fontFamily: themeConfig.token.fontFamilyCode,
      color: colors["coral-7"],
    },
    links: {
      color: colors["biobotblue-5"],
    },
  },
  colors: {
    primary: { main: colors["navy-7"] },
    success: { main: colors["green-7"] },
    error: { main: colors["red-7"] },
    text: {
      primary: colors["navy-7"],
      secondary: colors["navy-7"],
    },
    http: {
      delete: colors["pink-7"],
      get: colors["green-7"],
      head: colors["yellow-7"],
      patch: colors["orange-7"],
      post: colors["biobotblue-6"],
      put: colors["purple-6"],
    },
  },
  rightPanel: {
    backgroundColor: colors["navy-8"],
  },
  extensionsHook: (name: string) => extensionCss[name] ?? "",
} satisfies RedocTheme;

export default redocTheme;
