import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { LinkComponentProps, useLinkComponent } from "./links";
import type { Datasets } from "./types";

interface DownloadCardProps<T extends Datasets> extends LinkComponentProps<T> {
  className?: string;
  title: string;
  singularTitle?: string;
  image: string;
  datasets: T;
}

const DownloadCard = <T extends Datasets>({
  className,
  title,
  singularTitle,
  image,
  datasets,
  ...props
}: DownloadCardProps<T>) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title={title}
        image={image}
        singularTitle={singularTitle}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          {datasets.map((k: T[number]) => {
            const info = links[k];
            if (!info?.href) {
              return null;
            }
            return (
              <ExportButton
                key={k}
                label={info.label}
                href={info.href}
                gaAction={`${k}-downloaded`}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default DownloadCard;
