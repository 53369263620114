import type { RedocMenuItem } from "@repo/redoc-embed";
import { create } from "zustand";

import { ROUTES } from "src/routes/routeMap";

import MethodTag from "./MethodTag";

interface MenuItemsState {
  items?: RedocMenuItem[];
  setMenuItems: (items: RedocMenuItem[]) => void;
}

export const useMenuItemsStore = create<MenuItemsState>()((set) => ({
  items: undefined,
  setMenuItems: (items) => set(() => ({ items })),
}));

const itemLabel = (item: RedocMenuItem) => {
  switch (item.type) {
    case "operation":
      return (
        <span className="flex flex-row gap-1 items-center">
          <MethodTag method={item.httpVerb!} className="flex-none" />
          <span>{item.name}</span>
        </span>
      );
    default:
      return item.name;
  }
};

const parseMenuItem = (item: RedocMenuItem) => ({
  path: `${ROUTES.docs}#${item.id}`,
  label: itemLabel(item),
  children: item.items.length > 0 ? item.items.map(parseMenuItem) : undefined,
});

const useApiDocsMenuItems = () => {
  const items = useMenuItemsStore((state) => state.items);
  return items?.map(parseMenuItem);
};

export default useApiDocsMenuItems;
