import covidImage from "src/images/covid_virus.png";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

const datasets = [
  "covidNWSS",
  "covidCDC",
  "covidKits",
  "covidCountyInterpolated",
  "covidCountyAverage",
  "covidStateAverage",
  "covidRegionalAverage",
  "covidNationalAverage",
] as const;

interface CovidDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const CovidDownloads = (props: CovidDownloadsProps) => (
  <DownloadCard
    {...props}
    datasets={datasets}
    title="SARS-CoV-2"
    image={covidImage}
  />
);

export default CovidDownloads;
